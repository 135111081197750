<template>
    <ValidationObserver ref="banks" v-slot="{ handleSubmit }">
        <div v-loading="loading" class="card mb-g show">
            <div class="row row-grid no-gutters dropdown-menu-animated">
                <div class="col-12">
                    <div class="p-3">
                        <h2 class="mb-0 fs-xl header_bank">
                            Liên kết pancake
                        </h2>
                    </div>
                </div>
                <div class="col-12">
                    <div class="p-3">
                        <form @submit.prevent="handleSubmit(submit)" autocomplete="off">
                            <div class="form-row">
                                <div class="col-md-12 mb-3">
                                    <label class="form-label">ID Pancake<span class="text-danger">*</span></label>
                                    <ValidationProvider vid="pancake_id" name="Id pancake" rules="required"
                                        v-slot="{ errors, classes }">
                                        <div class="d-flex">
                                            <div class="input-group">
                                                <input type="text" class="form-control" :class="classes"
                                                    v-model="pancake_id" placeholder="Id pancake" autocomplete="false">
                                                <div class="invalid-feedback" data-field="name"
                                                    data-validator="notEmpty">
                                                    {{ errors[0] }}
                                                </div>
                                            </div>
                                            <div style="margin-left: 30px;">
                                                <button v-if="!has_pancake"
                                                    style="height: calc(1.47em + 1rem + 2px);width: 100px"
                                                    :disabled="loading"
                                                    class="btn btn-primary waves-effect waves-themed">
                                                    <i v-if="loading" class="el-icon-loading"></i>
                                                    <span>Liên kết</span>
                                                </button>
                                                <button v-else style="height: calc(1.47em + 1rem + 2px);width: 120px"
                                                    :disabled="loading"
                                                    class="btn btn-cancel waves-effect waves-themed">
                                                    <i v-if="loading" class="el-icon-loading"></i>
                                                    <span>Hủy Liên kết</span>
                                                </button>
                                            </div>
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </ValidationObserver>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { GET_USER_PANCAKE, ADD_USER_PANCAKE, CANCEL_USER_PANCAKE } from "@/core/services/store/user/pancake.module";
export default {
    name: "EdutalkPancake",
    data() {
        return {
            pancake_id: '',
            loading: true,
            has_pancake: false
        }
    },
    watch: {
    },
    mounted() {
        this.getPancake();
    },
    methods: {
        getPancake() {
            this.has_pancake = false;
            this.$store.dispatch(GET_USER_PANCAKE, { user_id: this.currentUser.id })
                .then((res) => {
                    this.pancake_id = res.data;
                    if (res.data) {
                        this.has_pancake = true;
                    }
                    this.loading = false;
                });
        },
        submit() {
            if (!this.has_pancake) {
                this.addPancake();
            } else {
                this.cancelPancake();
            }
        },

        addPancake() {
            let params = {
                user_id: this.currentUser.id,
                pancake_id: this.pancake_id
            }
            this.$store.dispatch(ADD_USER_PANCAKE, params)
                .then((res) => {
                    this.$message({
                        type: 'success',
                        message: 'Liên kết pancake thành công!',
                        showClose: true
                    });
                    this.loading = false;
                    this.getPancake();
                })
                .catch((error) => {
                    this.$message({
                        type: 'error',
                        message: error?.data?.message ? error.data.message : 'Liên kết pancake thất bại!',
                        showClose: true
                    });
                    this.loading = false;
                });
        },
        cancelPancake() {
            this.$store.dispatch(CANCEL_USER_PANCAKE, { user_id: this.currentUser.id })
                .then((res) => {
                    this.$message({
                        type: 'success',
                        message: 'Hủy liên kết pancake thành công!',
                        showClose: true
                    });
                    this.loading = false;
                    this.getPancake();
                })
                .catch((error) => {
                    this.$message({
                        type: 'error',
                        message: error?.data?.message ? error.data.message : 'Hủy liên kết pancake thất bại!',
                        showClose: true
                    });
                    this.loading = false;
                });
        }
    },
    computed: {
        ...mapState({
            errors: state => state.auth.errors
        }),
        ...mapGetters(['currentUser'])
    }
};
</script>
<style scoped>
.btn-cancel {
    color: #fff;
    background-color: #535353;
    border-color: #535353;
}
</style>
